export const parseRichText = (unresolvedContentObject) => {
  let plainText = '';
  const bodyContent = JSON.parse(unresolvedContentObject?.raw);

  const traverseNodes = (nodes) => {
    nodes.forEach((node) => {
      if (node.nodeType === 'text') {
        plainText += node.value + ' ';
      } else if (node.content) {
        traverseNodes(node.content);
      }
    });
  };

  if (bodyContent && bodyContent.content) {
    traverseNodes(bodyContent.content);
  }

  return plainText.trim();
};